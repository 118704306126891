.small-container{
  max-width: 1080px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

/*---------product details-----------*/
.single-product{
  margin-top: 80px;
}
.single-product .col-2 img{
  padding: 0;
}
.single-product .col-2{
  padding: 20px;
}

.single-product h4{
  margin: 20px 0;
  font-size: 22px;
  font-weight: bold;
}

.single-product select{
  display: block;
  padding: 10px;
  margin-top: 20px;
}

.single-product input{
  width: 50px;
  height: 40px;
  padding-left: 10px;
  font-size: 20px;
  margin-right: 10px;
  border: 1px solid #ff523b;
}
input:focus{
  outline: none;
}

.single-product .fa{
  color: #ff523b;
  margin-left: 10px;
}
.small-img-row{
  display: flex;
  justify-content: space-between;
}
.small-img-col{
  flex-basis: 24%;
  cursor: pointer;
}


/*-------600 media query---------*/



@media only screen and (max-width: 600px){
  .single-product .row{
      text-align: left;
  }
  .single-product .col-2{
      padding: 20px 0;
  }

  .single-product h1{
      font-size: 26px;
      line-height: 22px;
  }
}

.row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
/*---------*/
  
}
@media only screen and (max-width: 600px){
    
  .row{
    text-align: center;
  }
  
  .col-1, .col-2, .col-3, .col-4{
      
      flex-basis: 100%;
  }
}

.col-2{
  flex-basis: 50%;
  min-width: 300px;
}

.col-2 img{
  max-width: 100%;
  padding: 50px 0;
}
.col-2 h1{
  font-size: 50px;
  line-height: 60px;
  margin: 25px 0;
}
.btn{
  display: inline-block;
  background: #043b5d;
  color: #fff;
  padding: 8px 10px;
  margin: 5px 0;
  border-radius: 10px;
  transition: background 0.5s;
}
.btn:hover{
  background: #1b6592;
}


.col-4{
  flex-basis: 25%;
  padding: 10px;
  min-width: 200px;
  margin-bottom: 50px;
  transition: transform 0.5s;
}
.col-4 img{
  width: 100%;
}
h4{
  color: #555;
  font-weight: normal;
}
.col-4 p{
  font-size: 14px;
}
.rating .fa{
  color: #ff523b;
}

.col-4:hover{
  transform: translateY(-5px);
}






