@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navbar{
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: solid gray !important;
}
.nav-link{
  font-weight: 300 !important;
}
.active{
  border-bottom: 1px solid black;
}
.navbar-brand{
  font-weight: 700;
  font-family: 'roboto, sans-serif';
  letter-spacing: 3px;
}

/* -----------footer----------- */
.footer{
  color: white;
  padding: 25px;
  background: #000000;
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  );
  background: linear-gradient(to right, #434343, #000000);
}
.footer a{
  text-decoration: none;
  color: white;
  padding: 10px;
}
.footer a:hover{
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}

/* ------------Page Not Found------------ */

.pnf{
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pnf-title{
  font-size: 100px;
  font-weight: 700;
}
.pnf-heading{
  font-weight: normal;
}
.pnf-btn{
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover{
  background-color: black;
  color: white;
}


.contactus{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contactus img{
  margin-top: 33px;
}

/* ------------------register page------------ */
.register{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  flex-direction: column;
}







  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Playfair Display", serif; */


